import { Analytics } from '@analytics';
import RFIDIcon from '@assets/images/RFID.svg';
import TextIconInformationBox from '@components/Onboarding/TextIconInformationBox';
import { TextIconBoxType } from '@components/Onboarding/constants';
import { StyleBreakpoints } from '@constants';
import {
  Button,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatRegionalLinks } from '@utils/helpers';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { currencySign } from '../../../utils/helpers';
import CustomizableModal from '../../Common/CustomizableModal';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up(StyleBreakpoints.xs)]: {
      width: '80vw',
      maxWidth: '600px'
    }
  },
  topBlock: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  rfidIcon: {
    height: '56px',
    width: '56px'
  },
  heading: {
    fontSize: '30px',
    fontWeight: '700',
    color: '#000',
    textAlign: 'center'
  },
  subHeading: {
    fontSize: '21px',
    fontWeight: '500',
    color: '#000',
    textAlign: 'center'
  },
  inputContainer: {
    marginTop: '25px',
    marginBottom: '15px',
    maxWidth: '490px'
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      flexWrap: 'wrap'
    },
    '&:not(:first-child)': {
      marginTop: '32px'
    },
    '& .MuiInputBase-input': {
      fontSize: '16px',
      fontWeight: '600',
      color: '#5a5a5a'
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      fontWeight: '600',
      color: '#5a5a5a'
    }
  },
  inputAdornment: {
    '& .MuiTypography-root': {
      fontSize: '16px',
      fontWeight: '700',
      color: '#5a5a5a'
    }
  },
  qrPaper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'column',
    margin: ' 0',
    padding: '10px',
    maxWidth: '340px  !important'
  },
  qrCode: {
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '10px'
  },
  sectionBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '16px',
    backgroundColor: '#f9f9f9',
    padding: '20px 16px',
    gap: '20px',
    maxWidth: '490px'
  },
  textIconContainer: {
    maxWidth: '340px'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 220,
    height: 220,
    [theme.breakpoints.up(StyleBreakpoints.xs)]: {
      width: 240,
      height: 240
    },
    [theme.breakpoints.up(StyleBreakpoints.xs)]: {
      width: 300,
      height: 300
    }
  },
  shareButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  shareButton: {
    width: '100%',
    maxWidth: '340px'
  }
}));

const PaymentLinkQRGeneratorModal = ({ isOpen, setIsOpen, currency, userId, companyName }) => {
  const intl = useIntl();
  const classes = useStyles();
  const qrGenerationTimeout = useRef(null);
  const [tag, setTag] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentLinkUrl, setPaymentLinkUrl] = useState(
    formatRegionalLinks(`t/g/${userId}?l=true&ti=false`)
  );

  function handleClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (qrGenerationTimeout.current) {
      clearTimeout(qrGenerationTimeout.current);
    }

    qrGenerationTimeout.current = setTimeout(() => {
      handleQRGeneration();
    }, 1000);
  }, [tag, paymentAmount]);

  // Trim digits after decimal place to prevent generating invalid amounts
  const trimAmount = amount => {
    const amountSections = amount.split('.');

    return `${amountSections[0]}${
      amountSections[1] ? `.${amountSections[1].substring(0, 2)}` : ''
    }`;
  };

  useEffect(() => {
    if (isOpen) {
      Analytics.trackViewFeature('PaymentLink');
    }
  }, [isOpen]);

  const handleQRGeneration = () => {
    if (isOpen) {
      Analytics.track('generated new payment link qr code');
    }

    // Using URI encoding to preserve special characters used in tag in url
    setPaymentLinkUrl(
      formatRegionalLinks(
        `t/g/${userId}?l=true&ti=false${paymentAmount ? `&v=${paymentAmount}` : ''}${
          tag ? `&t=${encodeURIComponent(tag)}` : ''
        }`
      )
    );

    setIsLoading(false);
  };

  // Timeout after input to prevent repeatedly re-generating qr code
  const handleInputChange = (e, input) => {
    setIsLoading(true);

    Analytics.trackUseFeature('PaymentLink');

    if (input === 'tag') setTag(e.target.value);
    if (input === 'paymentAmount') setPaymentAmount(trimAmount(e.target.value));
  };

  const imageSettings = {
    height:
      window.innerWidth <= StyleBreakpoints.xs
        ? 220
        : window.innerWidth <= StyleBreakpoints.sm
        ? 240
        : 300,
    width:
      window.innerWidth <= StyleBreakpoints.xs
        ? 220
        : window.innerWidth <= StyleBreakpoints.sm
        ? 240
        : 300,
    x: 0,
    y: 0
  };

  const size =
    window.innerWidth <= StyleBreakpoints.xs
      ? 220
      : window.innerWidth <= StyleBreakpoints.sm
      ? 240
      : 300;

  const getModalBody = () => {
    return (
      <div className={classes.modalBody}>
        <div className={classes.topBlock}>
          <img className={classes.rfidIcon} src={RFIDIcon} alt="" />
          <div>
            <div className={classes.heading}>{intl.formatMessage(messages.paymentLink)}</div>
            <div className={classes.subHeading}>{intl.formatMessage(messages.tagAndAmount)}</div>
          </div>
        </div>
        <div className={classes.inputContainer}>
          <TextField
            multiline
            className={classes.input}
            label={intl.formatMessage(messages.tagRequired)}
            variant="outlined"
            value={tag}
            onChange={e => handleInputChange(e, 'tag')}
          />
          <OutlinedInput
            className={classes.input}
            startAdornment={
              <InputAdornment className={classes.inputAdornment} sx={{ fontSize: '16px' }}>
                {currencySign(currency)}
              </InputAdornment>
            }
            variant="outlined"
            type="number"
            value={paymentAmount}
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*[.,]?[0-9]*',
              step: 1,
              style: { textAlign: 'center' }
            }}
            onChange={e => handleInputChange(e, 'paymentAmount')}
          />
        </div>
        <div className={classes.sectionBlock}>
          <Paper className={classes.qrPaper}>
            <div className={classes.qrCode}>
              {isLoading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : (
                <QRCodeCanvas value={paymentLinkUrl} size={size} imageSettings={imageSettings} />
              )}
            </div>
          </Paper>
          <div className={classes.textIconContainer}>
            <TextIconInformationBox
              card={TextIconBoxType.PaymentLinkQR}
              noBackgroundCircle={true}
            />
          </div>
          <div className={classes.shareButtonContainer}>
            <Button
              className={classes.shareButton}
              variant="contained"
              color="primary"
              onClick={() => {
                Analytics.track('share direct payment link clicked');

                navigator.share({
                  title: `${companyName} payment`,
                  url: paymentLinkUrl
                });
              }}>
              {intl.formatMessage(messages.shareLink)}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomizableModal
        handleHeaderCloseButton={true}
        isOpen={isOpen}
        onClose={handleClose}
        withHeader={true}
        withFooter={false}
        body={getModalBody()}
      />
    </>
  );
};

export default PaymentLinkQRGeneratorModal;
