/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Analytics } from '@analytics';
import UsersIcon from '@assets/images/MemberIcon.svg';
import ResourcesIcon from '@assets/images/ResourcesIcon.svg';
import EmojiAvatar from '@components/EmojiAvatar';
import { CardViewType, GroupEntityType, StyleBreakpoints } from '@constants';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import GroupResourceDetails from '../GroupResourceDetails';
import GroupUserDetails from '../GroupUserDetails';
import * as actions from '../TeamSettings/actions';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: props => ({
    padding: '18px 18px 18px 18px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.23)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0',
    margin: 'auto',
    marginBottom: '16px',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: props.fullSize ? '100%' : '400px'
    }
  }),
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    justifyContent: 'space-between',
    width: '100%'
  },
  teamInfo: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px'
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000'
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#a0a0a0'
  },
  icon: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  element: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  elementName: {
    marginLeft: '16px',
    flex: 1
  },
  removeButton: {
    justifyContent: 'flex-end'
  },
  avatarIcon: {
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
  },
  teamSettings: {
    color: 'blue',
    fontSize: 11,
    fontWeight: 600,
    cursor: 'pointer'
  }
}));

const GroupDetailsCard = ({
  group,
  isAGroup = true,
  type = GroupEntityType.resource,
  user,
  elements,
  showRemoveFromTeamDialog,
  isUserInGroup,
  fullSize = false,
  setCardView,
  teamDetails,
  clearTeamDetails,
  currentUser
}) => {
  const intl = useIntl();
  const classes = useStyles({ fullSize });

  const mapToRemoveDialog = element => {
    if (type === GroupEntityType.resource) {
      return {
        icon: <EmojiAvatar code={element.emoji || ':credit_card:'} />,
        groupId: group._id,
        type: type,
        id: element._id,
        description: `${element.name} - ${intl.formatMessage(messages.removeResourceConfirmation)}`
      };
    }

    return {
      icon: element.profile.avatarUrl ? (
        <img
          className={`${classes.icon} ${classes.avatarIcon}`}
          src={element.profile.avatarUrl}
          alt="User avatar"
        />
      ) : (
        <AccountCircleIcon
          className={`${classes.icon} ${classes.avatarIcon} ${classes.accountIcon}`}
        />
      ),
      groupId: group._id,
      type: type,
      id: element._id,
      description: `${element.profile.firstName} ${element.profile.lastName} - ${intl.formatMessage(
        messages.removeMemberConfirmation
      )}`
    };
  };

  const showElementDetails = element => {
    switch (type) {
      case GroupEntityType.resource: {
        return (
          <GroupResourceDetails
            key={element._id}
            name={element.name}
            emoji={element.emoji}
            shouldShowRemoveButton={isUserInGroup || currentUser.role === 'system_admin'}
            handleRemoveFromTeam={() => showRemoveFromTeamDialog(mapToRemoveDialog(element))}
          />
        );
      }
      case GroupEntityType.user: {
        return (
          <GroupUserDetails
            key={element._id}
            name={element.name}
            avatarUrl={element.profile.avatarUrl}
            firstName={element.profile.firstName}
            lastName={element.profile.lastName}
            isTheSameUser={element._id === user?.userId}
            shouldShowRemoveButton={
              (isUserInGroup || currentUser.role === 'system_admin') && element._id !== user.userId
            }
            handleRemoveFromTeam={() => showRemoveFromTeamDialog(mapToRemoveDialog(element))}
            splitPercentage={
              teamDetails?.userTipSplit.tipBreakdown.find(user => user.userId === element._id)
                ?.splitPercentage || 0
            }
            isUserInGroup={isUserInGroup}
          />
        );
      }
      case GroupEntityType.splitTip: {
        return (
          <GroupUserDetails
            key={element._id}
            name={element.name}
            avatarUrl={element.profile.avatarUrl}
            firstName={element.profile.firstName}
            lastName={element.profile.lastName}
            isTheSameUser={element._id === user?.userId}
            shouldShowRemoveButton={false}
          />
        );
      }
      default:
        return null;
    }
  };

  const handleTeamSettingsClick = () => {
    Analytics.track('Team Settings Clicked');

    // clear team details before showing the team settings, to fetch the latest data
    clearTeamDetails();
    setCardView(CardViewType.TeamSettings);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <div className={classes.teamInfo}>
            <div className={classes.icon}>
              <img
                src={type === GroupEntityType.resource ? ResourcesIcon : UsersIcon}
                alt="resources icon"
              />
            </div>
            <div className={classes.headerText}>
              <div className={classes.title}>
                {type === GroupEntityType.resource
                  ? intl.formatMessage(messages.resources)
                  : intl.formatMessage(messages.members)}
              </div>
              <div className={classes.subTitle}>
                {isAGroup
                  ? intl.formatMessage(messages.subText)
                  : intl.formatMessage(messages.noTeam)}
              </div>
            </div>
          </div>

          {type === GroupEntityType.user && isUserInGroup && (
            <div onClick={handleTeamSettingsClick} className={classes.teamSettings}>
              {intl.formatMessage(messages.teamSettings)}
            </div>
          )}
        </div>
        {elements && elements.map(showElementDetails)}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  teamDetails: state.teamSettings?.teamDetails,
  currentUser: state.user?.user
});

const mapDispatchToProps = dispatch => ({
  clearTeamDetails: () => dispatch(actions.clearTeamDetails())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupDetailsCard);
