import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import { StyleBreakpoints } from '@constants';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import Payouts from './StripeConnectPortal/Payouts';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  contentContainer: {
    padding: '0 16px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const Balance = ({ user }) => {
  const classes = useStyles();
  return (
    <>
      <TopNav />
      <div className={classes.container}>
        <TipDirectLeftMenu selectedTab={'Balance'} />
        <div className={classes.contentContainer}>
          <Payouts user={user} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user?.user
});

export default connect(mapStateToProps)(Balance);
