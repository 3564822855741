import { Analytics } from '@analytics';
import ButtonWithMenu from '@components/Common/ButtonWithMenu';
import CustomizableModal from '@components/Common/CustomizableModal';
import localizationMessages from '@components/Resources/ResourcesTable/messages';
import AccessControlledButton from '@components/TapToTip/AccessControlledButton';
import AccessControlledTableBody from '@components/TapToTip/AccessControlledTableBody';
import CreateTourModal from '@components/TapToTip/CreateTourModal';
import ManageGuidesModal from '@components/TapToTip/ManageGuidesModal';
import { StyleBreakpoints } from '@constants';
import { MenuItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CustomSection, { SectionBadge } from '../CustomSection';
import { StatusBackgroundColor, StatusForeColor, StatusType } from './constants';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  actionButton: {
    display: 'flex',
    width: '100%',
    marginTop: '20px'
  },
  status: {
    textAlign: 'center',
    width: '86px',
    padding: '4px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '4px'
  },
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    },
    width: '100%'
  },
  tourTitle: {
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    overflow: 'hidden',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: '150px'
    },

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      maxWidth: '200px'
    }
  }
}));

function mapTourForEditing(tour) {
  const lang = tour.translations.primaryLanguage;
  return {
    _id: tour._id,
    primaryLanguage: lang,
    title: tour.translations.translations[lang].data.title,
    description: tour.translations.translations[lang].data.longDescription,
    reviewSites: tour.reviewSites
  };
}

const TourStatus = ({ status, classes }) => {
  return (
    <div
      className={classes.status}
      style={{
        backgroundColor: StatusBackgroundColor[status],
        color: StatusForeColor[status]
      }}>
      {StatusType[status]}
    </div>
  );
};

const ToursSection = ({
  tours,
  addTapToTipTour,
  editTapToTipTour,
  agentsProfiles,
  multipleAssign,
  deleteTour,
  isLoading
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isAddTourModalOpen, setIsAddTourModalOpen] = useState(false);
  const [isManageGuidesOpen, setIsManageGuidesOpen] = useState(false);
  const [tourDataToEdit, setTourDataToEdit] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tourToDelete, setTourToDelete] = useState({});

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleTourRemove = tours => {
    deleteTour(tours._id);
    Analytics.track('delete tour clicked');
    handleModalClose();
  };

  function openEditTourModal(data) {
    setTourDataToEdit(mapTourForEditing(data));
    setIsAddTourModalOpen(true);
  }

  function openAddTourModal() {
    setTourDataToEdit(null);
    setIsAddTourModalOpen(true);
    Analytics.track('create TTT tour opened');
  }

  const getMenuBody = rowData => [
    <AccessControlledButton
      key={rowData._id}
      feature="PostTipReviewCollection"
      as="MenuItem"
      title={intl.formatMessage(messages.editTour)}
      onClick={() => {
        openEditTourModal(rowData);
        Analytics.track('edit TTT tour opened');
      }}
    />,
    <MenuItem
      key={rowData._id}
      onClick={() => {
        setIsDeleteModalOpen(true);
        setTourToDelete(rowData);
        Analytics.track('delete TTT tour opened');
      }}>
      {intl.formatMessage(messages.deleteTour)}
    </MenuItem>
  ];

  const columns = [
    {
      field: 'status',
      title: intl.formatMessage(messages.status),
      customSort: (a, b) => {
        return (a.reviewSites?.length > 0) - (b.reviewSites?.length > 0);
      },
      render: rowData => (
        <TourStatus status={rowData.numberTourSites ? 'active' : 'incomplete'} classes={classes} />
      )
    },
    {
      field: 'title',
      title: intl.formatMessage(messages.tour),
      render: rowData => {
        return <div className={classes.tourTitle}>{rowData.title}</div>;
      }
    },
    {
      field: 'numberTourSites',
      title: intl.formatMessage(messages.reviewLinks),
      defaultSort: 'desc',
      render: rowData => {
        return (
          <div className={classes.status}>
            {rowData.numberTourSites} {rowData.numberTourSites > 1 ? 'Links' : 'Link'}
          </div>
        );
      }
    },
    {
      title: intl.formatMessage(messages.manage),
      field: 'manage',
      sorting: false,
      headerStyle: {
        paddingRight: '25px',
        textAlign: 'right'
      },
      cellStyle: {
        textAlign: 'right'
      },
      render: rowData => <ButtonWithMenu menuBody={getMenuBody(rowData)} />
    }
  ];

  const toursWithTitles = tours?.map(tour => {
    return {
      ...tour,
      title: tour.translations.translations[tour.translations.primaryLanguage].data.title,
      numberTourSites: tour.reviewSites
        ? tour.reviewSites.filter(site => site.url && site.url.length).length
        : 0
    };
  });

  return (
    <>
      <CustomSection
        id="Tours"
        badge={SectionBadge.PRO}
        title={intl.formatMessage(messages.tours)}
        description={intl.formatMessage(messages.description)}
        feature="PostTipReviewCollection"
        shouldCheckFeature
        isForm={false}>
        <MaterialTable
          isLoading={isLoading}
          className={classes.table}
          title={intl.formatMessage(messages.tours)}
          columns={columns}
          data={toursWithTitles}
          components={{
            Container: props => <Paper className={classes.striped} {...props} />
          }}
          options={{
            pageSize: 10,
            tableLayout: 'auto',
            emptyRowsWhenPaging: false,
            headerStyle: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              top: '0'
            },
            draggable: false,
            cellStyle: {
              whiteSpace: 'nowrap',
              width: 'fit-content'
            },
            toolbar: false,
            maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
          }}
          localization={{
            pagination: {
              labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
              labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
              firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
              firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
              previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
              previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
              nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
              nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
              lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
              lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
            },
            body: {
              emptyDataSourceMessage: (
                <AccessControlledTableBody
                  feature="PostTipReviewCollection"
                  title={intl.formatMessage(messages.emptyBodyTitle)}
                  description={intl.formatMessage(messages.emptyBodyDescription)}
                />
              )
            }
          }}
        />

        <AccessControlledButton
          elementStyle={classes.actionButton}
          feature="PostTipReviewCollection"
          tooltipTitle="Create new tours to collect reviews for"
          title={intl.formatMessage(messages.createTour)}
          onClick={openAddTourModal}
        />
      </CustomSection>

      <CreateTourModal
        isOpen={isAddTourModalOpen}
        setIsOpen={setIsAddTourModalOpen}
        addTapToTipTour={addTapToTipTour}
        setIsManageGuidesOpen={setIsManageGuidesOpen}
        updateTapToTipTour={editTapToTipTour}
        tourDetails={tourDataToEdit}
      />
      <ManageGuidesModal
        isOpen={isManageGuidesOpen}
        setIsOpen={setIsManageGuidesOpen}
        setIsAddTourModalOpen={setIsAddTourModalOpen}
        guides={agentsProfiles}
        tourDetails={tourDataToEdit}
        multipleAssign={multipleAssign}
      />
      <CustomizableModal
        isOpen={isDeleteModalOpen}
        onClose={handleModalClose}
        handleHeaderCloseButton={handleModalClose}
        title={intl.formatMessage(messages.confirmAction)}
        body={
          <p>
            {intl.formatMessage(messages.removeTourConfirm)}: <b>{tourToDelete.title}</b>
          </p>
        }
        handleFooterCloseButton={handleModalClose}
        primaryActionButtonData={{
          content: intl.formatMessage(messages.confirm),
          onClick: () => handleTourRemove(tourToDelete),
          isDisabled: false
        }}
      />
    </>
  );
};

export default ToursSection;
