import { createAction } from '@utils/helpers';
import * as actionTypes from './types';

export const updatePostTipFeature = createAction(actionTypes.UPDATE_POST_TIP_FEATURES);
export const updatePostTipFeatureSuccess = createAction(
  actionTypes.UPDATE_POST_TIP_FEATURES_SUCCESS
);

export const fetchAgentsProfiles = createAction(actionTypes.FETCH_AGENTS_PROFILES);
export const fetchAgentsProfilesSuccess = createAction(actionTypes.FETCH_AGENTS_PROFILES_SUCCESS);

export const addTapToTipTour = createAction(actionTypes.ADD_TTT_TOUR);
export const editTapToTipTour = createAction(actionTypes.EDIT_TTT_TOUR);
export const deleteTour = createAction(actionTypes.DELETE_TOUR);

export const fetchTours = createAction(actionTypes.FETCH_TOURS);
export const fetchToursSuccess = createAction(actionTypes.FETCH_TOURS_SUCCESS);

export const multipleAssign = createAction(actionTypes.MULTIPLE_ASSIGN);

export const facedError = createAction(actionTypes.ACTION_ERROR);
