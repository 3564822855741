import { defineMessages } from 'react-intl';

const scope = 'app.containers.TipDirectPages.Setup';

export default defineMessages({
  previewTDProfile: {
    id: `${scope}.previewTDProfile`,
    defaultMessage: 'Preview your TipDirect profile'
  },
  previewProfile: {
    id: `${scope}.previewProfile`,
    defaultMessage: 'Preview Profile'
  }
});
