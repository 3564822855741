export const UPDATE_POST_TIP_FEATURES = 'UPDATE_POST_TIP_FEATURES';
export const UPDATE_POST_TIP_FEATURES_SUCCESS = 'UPDATE_POST_TIP_FEATURES_SUCCESS';

export const FETCH_AGENTS_PROFILES = 'FETCH_AGENTS_PROFILES';
export const FETCH_AGENTS_PROFILES_SUCCESS = 'FETCH_AGENTS_PROFILES_SUCCESS';

export const ADD_TTT_TOUR = 'ADD_TTT_TOUR';
export const EDIT_TTT_TOUR = 'EDIT_TTT_TOUR';
export const DELETE_TOUR = 'DELETE_TOUR';

export const ACTION_ERROR = 'ACTION_ERROR';

export const FETCH_TOURS = 'FETCH_TOURS';
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';

export const MULTIPLE_ASSIGN = 'MULTIPLE_ASSIGN';