import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Setup.UpsellSection';

export default defineMessages({
  sectionTitle: {
    id: `${scope}.upsell`,
    defaultMessage: 'Upsell'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Create custom links and promo codes to upsell other products'
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save'
  },
  thankYouTitle: {
    id: `${scope}.title`,
    defaultMessage: 'Title'
  },
  thankYouText: {
    id: `${scope}.text`,
    defaultMessage: 'Text'
  },
  url: {
    id: `${scope}.url`,
    defaultMessage: 'Upsell Link'
  },
  promocode: {
    id: `${scope}.promocode`,
    defaultMessage: 'Promo Code'
  }
});
