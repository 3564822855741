import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Setup.CustomLinkSection';

export default defineMessages({
  title: {
    id: `${scope}.customLink`,
    defaultMessage: 'Custom Link'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Add an external link to help keep your customer engaged.'
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save'
  },
  url: {
    id: `${scope}.url`,
    defaultMessage: 'Custom Link'
  },
  buttonText: {
    id: `${scope}.buttonText`,
    defaultMessage: 'Button Text'
  }
});
