import { RemitStripePayoutLink } from '@api/payout';
import { createStripeConnectCustomAccount } from '@api/user';
import faceIcon from '@assets/images/payout_page/Details.png';
import claimtip from '@assets/images/payout_page/Transfer.svg';
import { CircularProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import StripeAccountOnboarding from '../../TipDirectPages/Balance/StripeConnectPortal/Onboarding';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  input: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  container: {},
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto'
  },
  paymentMethods: {
    border: '1px solid red'
  },
  outerSurface: {
    backgroundColor: '#f9f9f9',
    padding: 22,
    margin: 11,
    borderRadius: 16
  },
  innerSurface: {
    backgroundColor: 'white',
    padding: 12,
    paddingLeft: 12,
    borderRadius: 16
  },
  innerSurfaceButton: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    padding: 12,
    borderRadius: 16,
    marginBottom: '16px'
  },
  boldHeading: {
    color: 'black',
    fontWeight: 'bold'
  },
  imageStyle: {
    margin: 22
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonStyle: {
    padding: 8
  },
  iconImage: {
    width: '35px',
    height: '35px'
  },
  iconContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignitems: 'center',
    borderRadius: 35,
    marginRight: 12,
    width: '35px',
    height: '35px'
  },
  payoutLinkBold: {
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.22px',
    color: '#000'
  },
  iconAndLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    margin: '12px'
  }
}));

export default function StripeInfoCollect({ user }) {
  const classes = useStyles();
  let [errorText, setErrorText] = useState(false);

  const [stripeAccount, setStripeAccount] = useState(null);

  const onOnboardingExit = async () => {
    let resp = await RemitStripePayoutLink();
    window.location.href = '/tipdirect/balance';
  };

  //Create stripe account if one doesnt exist (Server decision)
  useEffect(() => {
    async function fetchStripeAccount() {
      let accountInfo = await createStripeConnectCustomAccount();
      setStripeAccount(accountInfo.data);
    }
    fetchStripeAccount();
  }, []);

  //User performs stripe onboarding
  let pageContent = (
    <div style={{ margin: 'auto' }}>
      <div style={{ margin: 'auto', marginBottom: '12px' }}>
        Please wait while we create a stripe connect custom account
      </div>
      <center>
        <CircularProgress />
      </center>
    </div>
  );

  if (stripeAccount) {
    pageContent = (
      <div style={{ margin: 'auto', width: '90%' }}>
        <StripeAccountOnboarding onExit={onOnboardingExit} user={user} />;
      </div>
    );
  }
  //Init outstanding transfer

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <Paper
          className={classes.iconContainer}
          elevation={3}
          style={{
            margin: 'auto',
            marginTop: '100px',
            marginBottom: '22px',
            width: '80px',
            height: '80px',
            borderRadius: '80px',
            overflow: 'hidden'
          }}>
          <img src={faceIcon} style={{ width: '60px', height: '60px', margin: 'auto' }} />
        </Paper>
        <Paper className={classes.outerSurface} elevation={0}>
          <Paper
            className={classes.innerSurface}
            elevation={3}
            style={{ display: 'flex', fontSize: 13 }}>
            <Paper className={classes.iconContainer} elevation={3}>
              <img src={claimtip} />
            </Paper>
            <div>
              <span className={classes.boldHeading}>Enter your details!</span> - We need to collect
              some additional info to process your payouts. You will only need to do this once.
            </div>
          </Paper>
        </Paper>
        {pageContent}
        <Paper
          className={classes.outerSurface}
          elevation={0}
          style={{ display: errorText.length > 0 ? 'block' : 'none' }}>
          <Paper
            className={classes.innerSurface}
            elevation={3}
            style={{ display: 'flex', fontSize: 13, color: 'red' }}>
            <div>{errorText}</div>
          </Paper>
        </Paper>
      </div>
      <div
        style={{
          textAlign: 'center',
          fontWeight: '600',
          fontSize: '12px',
          maxWidth: '240px',
          margin: 'auto',
          marginTop: '24px'
        }}
        className={classes.payoutLinkBold}>
        TipDirect payouts are made via secure end-to-end encryption.
        <br />
        <img
          style={{ marginTop: '4px' }}
          src={require('../../../assets/images/payout_page/Encryption.svg')}
        />
      </div>
    </div>
  );
}
