export class ApiError extends Error {
  constructor(message, details, status) {
    super(message);
    this.message = message;
    this.details = details;
    this.status = status;
  }

  static fromApiError(apiError) {
    if (!(apiError instanceof Error)) return new Error('Unknown error: ' + apiError);
    return new ApiError(
      apiError.response?.data?.error?.message || apiError.response?.data?.error || apiError.message,
      apiError.response?.data?.error?.details,
      apiError.response?.data?.error?.status
    );
  }

  static ofAndThrow(apiError) {
    throw ApiError.fromApiError(apiError);
  }
}
