import { addDevice } from '@api/taptotip';
import { getToken } from 'firebase/messaging';
import { browserName, deviceType } from 'react-device-detect';
import { messaging } from './firebase';

export const requestNotificationPermission = async () => {
  try {
    // Check for notification permission before attempting to get the token
    const permission = await Notification.requestPermission();

    if (permission !== 'granted') {
      console.error('Permission for notifications was denied');
      return;
    }

    // Attempt to get the FCM token
    const token = await getToken(messaging, {
      vapidKey: process.env.FIREBASE_VAPID_KEY
    });

    if (token) {
      await addDevice({
        token,
        os: 'web',
        deviceType,
        browser: browserName
      });
    }
  } catch (err) {
    console.error('Error requesting FCM token:', err);
  }
};
