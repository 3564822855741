import { Analytics } from '@analytics';
import ShareInviteIcon from '@assets/images/Share_Invite.png';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatRegionalLinks } from '@utils/helpers';
import { default as React } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles({
  shareButton: {
    backgroundColor: '#F0F0F0',
    color: '#ADADAD',
    width: 'calc( 100% - 22px )',
    margin: '11px 12px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    boxShadow: 'unset',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)'
    }
  },
  shareIcon: { height: '20px', marginRight: '10px' }
});

const ShareCompanyInviteButton = ({ companyName, inviteCode }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Button
      className={classes.shareButton}
      variant="contained"
      color="primary"
      onClick={() => {
        Analytics.track('share company invite link clicked');

        navigator.share({
          title: `Join ${companyName} on TipDirect`,
          url: formatRegionalLinks(`signupfrominvite/${inviteCode}`)
        });
      }}>
      <img className={classes.shareIcon} src={ShareInviteIcon} alt={'Share Invite'} />
      {intl.formatMessage(messages.companyInvite)}
    </Button>
  );
};

export default ShareCompanyInviteButton;
