import { defineMessages } from 'react-intl';

const scope = 'app.components.Nav.TopNav.JumpMenu';

export default defineMessages({
  jumpTo: {
    id: `${scope}.jumpTo`,
    defaultMessage: 'Jump to'
  }
});
