import { Analytics } from '@analytics';
import { Button, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterNone as CopyIcon } from '@material-ui/icons';
import { Alert as MuiAlert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import TopNav from '../../components/Nav/TopNav';
import TextIconInformationBox from '../../components/Onboarding/TextIconInformationBox';
import { TextIconBoxType } from '../../components/Onboarding/constants';
import { orderCardLink, StyleBreakpoints } from '../../utils/constants';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { TapToTipTabs } from '../TapToTipPage';
import reducer from '../TapToTipPage/reducer';
import saga from '../TapToTipPage/saga';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 16px 16px 16px',
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    maxHeight: '700px',
    justifySelf: 'start',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 93px)',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      height: 'calc(100% - 77px)'
    }
  },
  subContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '18px 18px 28px 18px',
    borderRadius: '17px',
    backgroundColor: '#f9f9f9',
    marginBottom: '14px'
  },
  button: {
    width: '100%',
    marginTop: '14px',
    fontSize: '15px',
    '&:visited': { color: '#fff' }
  },
  buttonMarginTop: {
    marginTop: '20px'
  },
  tippingLink: {
    marginTop: '20px',
    borderRadius: '5.5px',
    backgroundColor: '#e7e7e7',
    padding: '12px 53px',
    width: '100%',
    position: 'relative',
    '&:hover': { color: 'black' },
    cursor: 'pointer'
  },
  tippingLinkText: {
    fontWeight: '600',
    color: '#000',
    fontSize: '12px',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  copyIcon: {
    position: 'absolute',
    right: '17px',
    top: '10px',
    width: '20px',
    height: 'auto',
    color: '#000'
  }
}));

const OnboardingCompletionPage = ({ user }) => {
  useInjectReducer({ key: 'taptotip', reducer });
  useInjectSaga({ key: 'taptotip', saga });
  const [showAlert, setShouldShowCopyAlert] = useState(false);

  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const linkUrl =
    process.env.REGION == 'EU'
      ? `https://eu.tip.direct/t/g/${user.userId}`
      : process.env.REGION == 'QA'
      ? `https://qats.tripadmit.com/t/g/${user.userId}`
      : `https://us.tip.direct/t/g/${user.userId}`;

  const buttons = {
    tippingPage: {
      title: 'View Tipping Page',
      tab: TapToTipTabs.TAPTOTIP.toLowerCase()
    },
    inviteGuides: {
      title: 'Invite Guides',
      tab: TapToTipTabs.USERS.toLowerCase()
    },
    orderCards: {
      title: 'Order Cards'
    },
    previewTippingFlow: {
      title: 'Preview Tipping Flow',
      link: `${linkUrl}?preview=true`
    }
  };

  const handleNavigation = data => {
    Analytics.track(`Onboarding Complete - ${data.title} Button Clicked`);

    if (data.tab) {
      history.push(`/tipdirect/${data.tab}`, { tab: data.tab });
      return;
    }

    if (data.link) {
      window.open(data.link, '_blank');
    }
  };

  const handleCopy = () => {
    Analytics.track('Onboarding Complete - Tipping Link Copied');
    navigator.clipboard.writeText(linkUrl);
    setShouldShowCopyAlert(true);
  };

  return (
    <>
      <TopNav />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.subContainer}>
            <TextIconInformationBox card={TextIconBoxType.Congratulations} columnLayout />
          </div>
          <div className={classes.subContainer}>
            <TextIconInformationBox card={TextIconBoxType.PersonalTipping} noBackgroundCircle />
            <div
              role="button"
              className={classes.tippingLink}
              tabIndex={0}
              onKeyDown={() => {
                handleCopy();
              }}
              onClick={() => {
                handleCopy();
              }}>
              <div className={classes.tippingLinkText}>{linkUrl}</div>
              <CopyIcon className={classes.copyIcon} />
            </div>
            <Button
              className={`${classes.button} ${classes.buttonMarginTop}`}
              color="primary"
              variant="contained"
              onClick={() => {
                handleNavigation(buttons.tippingPage);
              }}>
              {intl.formatMessage(messages.viewTippingPage)}
            </Button>
          </div>
          {user.role != 'system_admin' && (
            <div className={classes.subContainer}>
              <TextIconInformationBox card={TextIconBoxType.GetPaid} noBackgroundCircle />
            </div>
          )}
          {user.role === 'system_admin' && (
            <>
              <div className={classes.subContainer}>
                <TextIconInformationBox card={TextIconBoxType.InviteGuides} noBackgroundCircle />
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleNavigation(buttons.inviteGuides);
                  }}>
                  {intl.formatMessage(messages.inviteGuides)}
                </Button>
              </div>
            </>
          )}
          <div className={classes.subContainer}>
            <TextIconInformationBox card={TextIconBoxType.PreviewTippingFlow} noBackgroundCircle />
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => {
                handleNavigation(buttons.previewTippingFlow);
              }}>
              {intl.formatMessage(messages.previewTippingFlow)}
            </Button>
          </div>
          <div className={classes.subContainer}>
            <TextIconInformationBox card={TextIconBoxType.OrderCards} noBackgroundCircle />
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              target="_blank"
              onClick={() => {
                handleNavigation(buttons.orderCards);
              }}
              href={orderCardLink}>
              {intl.formatMessage(messages.orderCards)}
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        open={showAlert}
        autoHideDuration={10000}
        onClose={() => {
          setShouldShowCopyAlert(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="success"
          onClose={() => {
            setShouldShowCopyAlert(false);
          }}>
          {intl.formatMessage(messages.copiedToClipboard)}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user?.user
});

export default connect(mapStateToProps)(OnboardingCompletionPage);
