import { StyleBreakpoints } from '@constants';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '12px',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: '#fff',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'none'
    }
  },
  jumpText: {
    fontWeight: 700,
    fontSize: 14,
    marginLeft: 12,
    flex: 2,
    color: '#000',
    marginRight: 20
  },
  jumpButton: {
    borderRadius: '12px',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
    textTransform: 'none',
    flex: 8,
    color: '#000',
    backgroundColor: '#f9f9f9'
  },
  activeJumpButton: {
    border: '1px solid #424770',
    backgroundColor: '#f9f9f9'
  },
  jumpButtonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
    fontSize: 14
  },
  jumpButtonIcon: {
    width: 20,
    height: 20
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    fontSize: '14px',
    fontWeight: 700,
    color: '#000',
    gap: '36px'
  },
  activeMenuItem: {
    backgroundColor: '#e9e9e9',
    borderRadius: '4px'
  },
  menuPaper: {
    borderRadius: '4.5px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#fff',
    marginTop: '10px',
    marginRight: '4px',
    padding: '8px 12px'
  },
  icon: {
    fontSize: '24px',
    color: '#a4a4a4'
  }
}));

const JumpMenu = ({ tabsInfo }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(tabsInfo?.[0]);
  const buttonRef = React.useRef(null);
  const classes = useStyles();
  const intl = useIntl();
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = item => {
    setSelectedItem(item);
    const targetBox = document.getElementById(item.name);

    if (targetBox) {
      targetBox.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    handleClose();
  };

  if (!tabsInfo?.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.jumpText}>{intl.formatMessage(messages.jumpTo)}:</div>
      <Button
        ref={buttonRef}
        className={`${classes.jumpButton} ${open ? classes.activeJumpButton : ''}`}
        id="jump-button"
        aria-controls={open ? 'jump-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <div className={classes.jumpButtonBox}>
          <img src={selectedItem?.icon} className={classes.jumpButtonIcon} alt="Icon" />
          <div>{selectedItem?.label}</div>
        </div>
        {open ? (
          <KeyboardArrowUp className={classes.icon} />
        ) : (
          <KeyboardArrowDown className={classes.icon} />
        )}
      </Button>
      <Menu
        id="jump-menu"
        anchorEl={anchorEl}
        open={open}
        classes={{ paper: classes.menuPaper }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: buttonRef.current ? buttonRef.current.offsetWidth : null
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'jump-button'
        }}>
        {tabsInfo.map((tabInfo, key) => {
          return (
            <MenuItem
              button
              key={key}
              className={`${classes.menuItem} ${
                tabInfo.name === selectedItem?.name ? classes.activeMenuItem : ''
              }`}
              onClick={() => handleSelectItem(tabInfo)}>
              <img src={tabInfo.icon} alt={tabInfo.label} />
              <div>{tabInfo.label}</div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default JumpMenu;
