import { defineMessages } from 'react-intl';

const scope = `app.containers.TipDirectPage`;

export default defineMessages({
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile'
  },
  payments: {
    id: `${scope}.payments`,
    defaultMessage: 'Payments'
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard'
  },
  tipDirect: {
    id: `${scope}.tipDirect`,
    defaultMessage: 'Get Tips'
  },
  balance: {
    id: `${scope}.balance`,
    defaultMessage: 'Balance'
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Users'
  },
  transactions: {
    id: `${scope}.transactions`,
    defaultMessage: 'Transactions'
  },
  reviews: {
    id: `${scope}.reviews`,
    defaultMessage: 'Reviews'
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources'
  },
  admin: {
    id: `${scope}.admin`,
    defaultMessage: 'Admin'
  },
  tours: {
    id: `${scope}.tours`,
    defaultMessage: 'Tours'
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings'
  },
  recommend: {
    id: `${scope}.recommend`,
    defaultMessage: 'Recommend'
  },
  usToAFriend: {
    id: `${scope}.us_to_a_friend`,
    defaultMessage: 'Us to a friend'
  },
  recommendToAFriend: {
    id: `${scope}.recommend_to_a_friend`,
    defaultMessage: 'Recommend To A Friend'
  },
  upgradeButton: {
    id: `${scope}.upgrade_button`,
    defaultMessage: 'Upgrade to Pro'
  },
  companyInvite: {
    id: `${scope}.companyInvite`,
    defaultMessage: 'Company Invite'
  },
  upsell: {
    id: `${scope}.upsell`,
    defaultMessage: 'Upsell'
  },
  social: {
    id: `${scope}.social`,
    defaultMessage: 'Social'
  },
  customLink: {
    id: `${scope}.customLink`,
    defaultMessage: 'Custom Link'
  },
  setup: {
    id: `${scope}.setup`,
    defaultMessage: 'Setup'
  }
});
