import { defineMessages } from 'react-intl';

const scope = `app.components.shareCompanyInviteButton`;

export default defineMessages({
  companyInvite: {
    id: `${scope}.companyInvite`,
    defaultMessage: 'Company Invite'
  }
});
