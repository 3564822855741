import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Setup.ToursSection';

export default defineMessages({
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  reviewLinks: {
    id: `${scope}.reviewLinks`,
    defaultMessage: 'Review links'
  },
  tour: {
    id: `${scope}.tour`,
    defaultMessage: 'Tour'
  },
  inviteGuides: {
    id: `${scope}.inviteGuides`,
    defaultMessage: 'Invite Guides'
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage'
  },
  createTour: {
    id: `${scope}.createTour`,
    defaultMessage: 'Create Tour'
  },
  editTour: {
    id: `${scope}.editTour`,
    defaultMessage: 'Edit Tour'
  },
  deleteTour: {
    id: `${scope}.deleteTour`,
    defaultMessage: 'Delete Tour'
  },
  tours: {
    id: `${scope}.tours`,
    defaultMessage: 'Tours'
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm'
  },
  confirmAction: {
    id: `${scope}.confirmAction`,
    defaultMessage: 'Confirm action'
  },
  removeTourConfirm: {
    id: `${scope}.removeTourConfirm`,
    defaultMessage: 'Confirm that you wish to delete the tour'
  },
  emptyBodyTitle: {
    id: `${scope}.emptyBodyTitle`,
    defaultMessage: 'No Tours created yet'
  },
  emptyBodyDescription: {
    id: `${scope}.emptyBodyDescription`,
    defaultMessage: 'To start gathering reviews, set up tours here.'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Setup and manage tours & their review links for your account'
  }
});
