import axios from 'axios';

export function uploadCompanyFont(companyId, font) {
  const data = new FormData();
  data.append('font', font);

  return axios
    .post(`/api/v1/settings/company/${companyId}/upload-font`, data)
    .then(res => res.data.data.url);
}

export function uploadFileAttachment(companyId, file) {
  const data = new FormData();
  data.append('files', file);

  return axios
    .post(`/api/v1/settings/company/${companyId}/files`, data)
    .then(res => res.data.data.url);
}

export function uploadEmailImage(companyId, file) {
  const data = new FormData();
  data.append('files', file);

  return axios
    .post(`/api/v1/settings/company/${companyId}/image`, data)
    .then(res => res.data.data.url);
}

export function getCompanyLanguage() {
  return axios
    .get('/api/v1/settings/me/company')
    .then(res => res.data.data?.systemPreferencesDetails?.language || 'en');
}

export function updateCompanyFeatures(companyId, features) {
  return axios
    .put(`/api/v1/settings/company/${companyId}/features`, features)
    .then(res => res.data.data);
}
