import { checkStripeConnectCustomUser, getStripeConnectSessionForUser } from '@api/user';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectAccountOnboarding, ConnectComponentsProvider } from '@stripe/react-connect-js';
import React, { useState } from 'react';

const AccountOnboardingUI = ({ onExit, user }) => {
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await getStripeConnectSessionForUser();
      if (!response.status == 200) {
        // Handle errors on the client side here
        const { error } = await response.data;
        console.error('An error occurred: ', error);
        //document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } else {
        const { client_secret: clientSecret } = await response.data;
        //document.querySelector('#error').setAttribute('hidden', '');
        return clientSecret;
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        user.profile.country.toUpperCase() === 'US'
          ? 'pk_live_51OXmFuG0wbPSSqSDvaCajTovzFeBiLnPGTGngPeizipUDt4RJH2WpDma6X3cWMecsVK3d8JbUNFUuevKTgkNEy15002Bc5c6ao'
          : 'pk_live_daEGE9atFcH2wypoqrX3mp3I00OdEoPpTk',
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: '#6997FF',
          buttonPrimaryColorBackground: '#6997FF',
          buttonPrimaryColorBorder: '#6997FF',
          buttonPrimaryColorText: '#FFFFFF',
          actionPrimaryColorText: '#6997FF',
          actionPrimaryTextDecorationColor: '#6997FF',
          formHighlightColorBorder: '#6997FF',
          formAccentColor: '#6997FF',
          colorDanger: '#E926BF',
          badgeSuccessColorText: '#3CE5F4',
          badgeWarningColorText: '#D73EC6',
          badgeDangerColorText: '#D73EC6',
          spacingUnit: '12px',
          fontFamily: "'Montserrat',Helvetica,Arial,sans-serif"
        }
      }
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectAccountOnboarding
        onExit={() => {
          console.log('The account has exited onboarding');
          checkStripeConnectCustomUser();
          onExit();
        }}
        collectionOptions={{
          fields: 'eventually_due',
          futureRequirements: 'include'
        }}
      />
    </ConnectComponentsProvider>
  );
};

function StripeOnboarding({ onExit, user }) {
  return <AccountOnboardingUI onExit={onExit} user={user} />;
}

export default StripeOnboarding;
