import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    backgroundColor: '#f9f9f9',
    borderRadius: '18px',
    width: '100%',
    height: 'fit-content',
    position: 'relative'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '18px'
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px'
  },
  title: {
    fontSize: '18px',
    color: '#000',
    fontWeight: 'bold'
  },
  description: {
    fontSize: '14px',
    color: '#000',
    fontWeight: 'normal'
  },
  badge: {
    position: 'absolute',
    top: '14px',
    right: '14px',
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '6px',
    padding: '4px 8px',
    width: 'fit-content',
    lineHeight: 'normal'
  },
  pro: {
    backgroundColor: '#4b4b4b',
    color: '#fff'
  },
  content: {
    width: '100%',
    height: 'fit-content'
  },
  actionButton: {
    width: '100%',
    marginTop: '16px'
  }
});

export const SectionBadge = {
  PRO: 'Pro'
};

const CustomSection = ({
  id,
  title,
  description,
  renderBody,
  buttonTitle,
  onSave,
  badge = null,
  schema,
  initialValues,
  isForm = true,
  children,
  feature = null,
  shouldCheckFeature = false
}) => {
  const classes = useStyles();
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();
  const isFeatureEnabled = useMemo(() => {
    if (shouldCheckFeature) {
      return isFeatureForCompanyEnabled(feature);
    }

    return true;
  }, [feature, isFeatureForCompanyEnabled, shouldCheckFeature]);

  const validateSchema = schema => values => {
    return schema
      .validate(values, {
        abortEarly: false,
        strict: false
      })
      .then(() => ({}))
      .catch(({ inner }) => {
        return inner.reduce(
          (errorInfo, { path, message }) => ({
            ...errorInfo,
            [path]: (errorInfo[path] || []).concat(message)
          }),
          {}
        );
      });
  };

  return (
    <div className={classes.container} id={id}>
      {badge && !isFeatureEnabled && (
        <div className={`${classes.badge} ${classes[badge.toLowerCase()]}`}>{badge}</div>
      )}

      <div className={classes.header}>
        <div className={classes.infoBlock}>
          <div className={classes.title}>{title}</div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>

      {isForm ? (
        <Formik
          onSubmit={onSave}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validate={validateSchema(schema)}>
          {formikProps => (
            <Form className={classes.content}>
              {renderBody(formikProps)}

              <Button
                className={classes.actionButton}
                color="primary"
                variant="contained"
                type="submit">
                {buttonTitle}
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        children
      )}
    </div>
  );
};

export default CustomSection;
