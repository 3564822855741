import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Setup.SocialSection';

export default defineMessages({
  title: {
    id: `${scope}.social`,
    defaultMessage: 'Social'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Add links to your social media'
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save'
  },
  thankYouTitle: {
    id: `${scope}.thankYouTitle`,
    defaultMessage: 'Title'
  },
  thankYouText: {
    id: `${scope}.thankYouText`,
    defaultMessage: 'Text'
  },
  facebook: {
    id: `${scope}.facebook`,
    defaultMessage: 'Facebook'
  },
  youtube: {
    id: `${scope}.youtube`,
    defaultMessage: 'YouTube'
  },
  twitter: {
    id: `${scope}.twitter`,
    defaultMessage: 'Twitter'
  },
  instagram: {
    id: `${scope}.instagram`,
    defaultMessage: 'Instagram'
  },
  tiktok: {
    id: `${scope}.tiktok`,
    defaultMessage: 'TikTok'
  },
  socialButtonTitle: {
    id: `${scope}.socialButtonTitle`,
    defaultMessage: 'Button Title'
  },
  url: {
    id: `${scope}.url`,
    defaultMessage: 'URL'
  },
  textFieldTitle: {
    id: `${scope}.title`,
    defaultMessage: 'Title'
  }
});
