import { getMessaging } from '@firebase/messaging';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCnCsIm75yv73Sd9IR5GwGKkBG6mtmXijc',
  authDomain: 'tip-direct.firebaseapp.com',
  projectId: 'tip-direct',
  storageBucket: 'tip-direct.firebasestorage.app',
  messagingSenderId: '802228965261',
  appId: '1:802228965261:web:d98dfc3b7c80ac29e977c4'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
