import { Analytics } from '@analytics';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import CustomSection from '../CustomSection';
import messages from './messages';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%'
  },
  textField: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#fff',

    '& .MuiInputBase-root': {
      color: '#000',
      fontWeight: 500
    }
  }
});

const initialCustomLink = {
  url: '',
  buttonText: ''
};

const customLinkSchema = yup.object({
  url: yup
    .string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[^\s]*)?$/,
      'Invalid URL format'
    )
    .required('Custom Link is required'),
  buttonText: yup.string().required('Button Text is required')
});

const CustomLinkSection = ({ externalLink, updateCompanyFeatures }) => {
  const intl = useIntl();
  const classes = useStyles();

  const handleSave = values => {
    Analytics.track('Custom Link Saved', {
      page: 'setup'
    });

    updateCompanyFeatures({
      externalLink: values
    });
  };

  const renderSectionBody = ({ errors, touched, values, handleChange, handleBlur }) => (
    <div className={classes.container}>
      <TextField
        type="text"
        name="url"
        className={classes.textField}
        label={intl.formatMessage(messages.url)}
        variant="outlined"
        placeholder={intl.formatMessage(messages.url)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.url}
        error={touched.url && Boolean(errors.url)}
        helperText={touched.url && errors.url}
      />
      <TextField
        type="text"
        name="buttonText"
        className={classes.textField}
        label={intl.formatMessage(messages.buttonText)}
        variant="outlined"
        placeholder={intl.formatMessage(messages.buttonText)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.buttonText}
        error={touched.buttonText && Boolean(errors.buttonText)}
        helperText={touched.buttonText && errors.buttonText}
      />
    </div>
  );

  return (
    <CustomSection
      id="CustomLink"
      title={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description)}
      renderBody={renderSectionBody}
      buttonTitle={intl.formatMessage(messages.save)}
      onSave={handleSave}
      schema={customLinkSchema}
      initialValues={externalLink || initialCustomLink}
    />
  );
};

export default CustomLinkSection;
