import { Analytics } from '@analytics';
import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import CustomLinkSection from '@components/TapToTip/Setup/CustomLinkSection';
import SocialSection from '@components/TapToTip/Setup/SocialSection';
import ToursSection from '@components/TapToTip/Setup/ToursSection';
import UpsellSection from '@components/TapToTip/Setup/UpsellSection';
import { StyleBreakpoints } from '@constants';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatRegionalLinks } from '@utils/helpers';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import * as actions from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    width: '100%',
    maxWidth: '540px',
    padding: '12px',

    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      margin: '0 auto'
    },

    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      maxWidth: '690px'
    },

    [theme.breakpoints.up(StyleBreakpoints.xl)]: {
      maxWidth: '750px'
    }
  },
  sections: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '540px',
    gap: '30px'
  },
  previewProfile: {
    backgroundColor: '#d7dbff',
    color: '#000',
    borderRadius: '25px',
    padding: '6px 6px 6px 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: '12px',
    fontWeight: 600,

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      fontSize: '14px',
      alignItems: 'center'
    }
  },
  previewButton: {
    backgroundColor: '#fff',
    borderRadius: '18px',
    padding: '8px 16px',
    color: '#000',
    fontSize: '12px',

    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 77%)'
    },

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      fontSize: '14px'
    }
  }
}));

const segmentToSectionMap = new Map([
  ['tours', 'Tours'],
  ['upsell', 'Upsell'],
  ['social', 'Social'],
  ['custom-link', 'CustomLink']
]);

const Setup = ({
  user,
  updateCompanyFeatures,
  postTipPageFeatures,
  tours,
  fetchTours,
  addTapToTipTour,
  editTapToTipTour,
  agentsProfiles,
  fetchAgentsProfiles,
  multipleAssign,
  deleteTour,
  isLoading
}) => {
  useInjectReducer({ key: 'setup', reducer });
  useInjectSaga({ key: 'setup', saga });
  const location = useLocation();
  const lastSegment = location.pathname.split('/').pop();
  const intl = useIntl();
  const classes = useStyles();

  // take updated postTipPageFeatures or use one from the user object
  const relevantPostTipPageFeatures = useMemo(
    () => postTipPageFeatures || user?.companyDetails?.features?.postTipPage,
    [user, postTipPageFeatures]
  );
  const linkUrl = formatRegionalLinks(`t/g/${user.userId}`);
  const previewTippingLink = `${linkUrl}?preview=true`;

  useEffect(() => {
    if (!tours?.length) {
      fetchTours();
    }
    if (!agentsProfiles?.length) {
      fetchAgentsProfiles();
    }
  }, []);

  const handleUpdateCompanyFeatures = feature => {
    updateCompanyFeatures(user.companyId, {
      postTipPage: {
        ...relevantPostTipPageFeatures,
        ...feature
      }
    });
  };

  return (
    <>
      <TopNav />

      <div className={classes.container}>
        <TipDirectLeftMenu selectedTab={'Setup'} exactTab={segmentToSectionMap.get(lastSegment)} />

        <div className={classes.content}>
          <div className={classes.previewProfile}>
            <span>👀 {intl.formatMessage(messages.previewTDProfile)}</span>
            <Button
              className={classes.previewButton}
              onClick={() => {
                Analytics.track('Preview TipDirect Profile clicked', {
                  page: 'setup'
                });
              }}
              target="_blank"
              href={previewTippingLink}>
              {intl.formatMessage(messages.previewProfile)}
            </Button>
          </div>

          <ToursSection
            tours={tours}
            addTapToTipTour={addTapToTipTour}
            editTapToTipTour={editTapToTipTour}
            agentsProfiles={agentsProfiles}
            multipleAssign={multipleAssign}
            deleteTour={deleteTour}
            isLoading={isLoading}
          />

          <UpsellSection
            upsell={relevantPostTipPageFeatures.upsell}
            updateCompanyFeatures={handleUpdateCompanyFeatures}
          />
          <SocialSection
            socialLinks={relevantPostTipPageFeatures.socialLinks}
            updateCompanyFeatures={handleUpdateCompanyFeatures}
          />
          <CustomLinkSection
            externalLink={relevantPostTipPageFeatures.externalLink}
            updateCompanyFeatures={handleUpdateCompanyFeatures}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user.user,
  postTipPageFeatures: state.setup?.postTipPageFeatures,
  isLoading: state.setup?.isLoading,
  tours: state.setup?.tours,
  agentsProfiles: state.setup?.agentsProfiles
});

const mapDispatchToProps = dispatch => ({
  updateCompanyFeatures: (companyId, features) =>
    dispatch(actions.updatePostTipFeature({ companyId, features })),
  fetchAgentsProfiles: () => dispatch(actions.fetchAgentsProfiles()),
  addTapToTipTour: payload => dispatch(actions.addTapToTipTour(payload)),
  editTapToTipTour: payload => dispatch(actions.editTapToTipTour(payload)),
  fetchTours: () => dispatch(actions.fetchTours()),
  multipleAssign: (tourIds, guideIds) => dispatch(actions.multipleAssign({ tourIds, guideIds })),
  deleteTour: payload => dispatch(actions.deleteTour(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Setup);
